/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1PaymentsSubscriptionPlansSubscriberMembershipsListParams,
  PaginatedSubscriberMembershipList,
  SubscriberMembership
} from '.././model'
import adminV1PaymentsSubscriberMembershipsRetrieveMutator from '../../shared/services/axios-client';
import adminV1PaymentsSubscriptionPlansSubscriberMembershipsListMutator from '../../shared/services/axios-client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1PaymentsSubscriberMembershipsRetrieve = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsSubscriberMembershipsRetrieveMutator>,) => {
      return adminV1PaymentsSubscriberMembershipsRetrieveMutator<SubscriberMembership>(
      {url: `/api/admin/v1/payments/subscriber-memberships/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1PaymentsSubscriptionPlansSubscriberMembershipsList = (
    subPk: string,
    params?: AdminV1PaymentsSubscriptionPlansSubscriberMembershipsListParams,
 options?: SecondParameter<typeof adminV1PaymentsSubscriptionPlansSubscriberMembershipsListMutator>,) => {
      return adminV1PaymentsSubscriptionPlansSubscriberMembershipsListMutator<PaginatedSubscriberMembershipList>(
      {url: `/api/admin/v1/payments/subscription-plans/${subPk}/subscriber_memberships/`, method: 'GET',
        params
    },
      options);
    }
  export type AdminV1PaymentsSubscriberMembershipsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriberMembershipsRetrieve>>>
export type AdminV1PaymentsSubscriptionPlansSubscriberMembershipsListResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriptionPlansSubscriberMembershipsList>>>
