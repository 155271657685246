<template>
  <div class="flex mb-4 justify-end items-center">
    <div class="flex items-center gap-4">
      <Button
        label="Create Subscription"
        class="px-4"
        @click="
          router.push({
            name: ROUTE_NAME.PAYMENT_SUBSCRIPTIONS_CREATE,
          })
        "
      />
    </div>
  </div>
  <AppCard class="mt-6 animate-fadein animate-duration-1000">
    <DataTable
      :value="activeSubscriptions"
      striped-rows
      row-hover
      selection-mode="single"
      @row-click="onRowClick"
    >
      <Column header="Title">
        <template #body="slotProps">
          <div class="text-base">
            <span class="font-bold">{{ slotProps.data.title }}</span
            ><br />
            <span :class="getSubtextColor(slotProps.data)">{{
              getSubtextForTitle(slotProps.data)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Subscribers">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              slotProps.data.subscriber_membership_count === null
                ? "-"
                : slotProps.data.subscriber_membership_count
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Amount">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              getFormattedCurrency(slotProps.data.amount)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Recurring Rev.">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              slotProps.data.recurring_revenue === null
                ? "-"
                : getFormattedCurrency(slotProps.data.recurring_revenue)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Frequency">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              getFrequencyDisplayValue(slotProps.data.payment_frequency)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Enrollment">
        <template #body="slotProps">
          <div class="text-base">
            <span
              v-if="slotProps.data.enrollment_status"
              :class="getStatusColor(slotProps.data.enrollment_status)"
              >{{ capitalize(slotProps.data.enrollment_status) }}</span
            >
          </div>
        </template>
      </Column>
      <Column header="Share">
        <template #body="slotProps">
          <div class="text-base">
            <button
              v-if="showShareLink(slotProps.data)"
              type="button"
              class="text-blue-600 hover:text-blue-800 inline-flex items-center cursor-pointer"
              @click="copyUrl(slotProps.data.share_url)"
            >
              <i class="ml-2 pi pi-copy" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      :always-show="true"
      :rows="pageSize"
      :total-records="totalRecords"
      template="
            FirstPageLink
            PrevPageLink
            CurrentPageReport
            NextPageLink
            LastPageLink
            RowsPerPageDropdown"
      current-page-report-template="Showing {first} to {last} of {totalRecords}"
      @page="onPageChange"
    />
  </AppCard>
</template>

<script setup lang="ts">
import AppCard from "@/shared/components/AppCard.vue";
import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import router from "@/router";
import Paginator, { type PageState } from "primevue/paginator";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { onMounted, computed } from "vue";
import { SubscriptionPlanStatus, type SubscriptionPlan } from "@/api/model";
import { useToast } from "primevue/usetoast";
import { getFormattedCurrency } from "@/shared/utils/helpers";
import { capitalize } from "lodash";
import { getFormattedDate, getFrequencyDisplayValue } from "../helpers";
import { useSubscriptions } from "../composables/useSubscriptions";

const toast = useToast();

const {
  paginatedSubscriptions,
  fetchSubscriptions,
  currentPage,
  pageSize,
  totalRecords,
} = useSubscriptions();

const subscriptions = computed(() => paginatedSubscriptions.value?.results);

const activeSubscriptions = computed(() => {
  return subscriptions.value?.filter(
    (subscription) => subscription.archived_at === null
  );
});

const copyUrl = async (url: string) => {
  try {
    await navigator.clipboard.writeText(url);
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "URL copied to clipboard",
      life: 5000,
    });
  } catch (err) {
    console.error("Failed to copy URL: ", err);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to copy URL",
      life: 5000,
    });
  }
};

const onPageChange = (event: PageState) => {
  currentPage.value = event.page;
  fetchSubscriptions();
};

const onRowClick = (event: DataTableRowClickEvent) => {
  router.push({
    name: ROUTE_NAME.PAYMENT_SUBSCRIPTIONS_VIEW,
    params: { id: event.data.id },
  });
};

const getStatusColor = (status: string) => {
  switch (status) {
    case "open":
      return "text-green-600";
    case "closed":
      return "text-red-600";
    case "archived":
      return "text-gray-600";
    default:
      return "";
  }
};

const showShareLink = (subscription: SubscriptionPlan) => {
  return subscription.status === SubscriptionPlanStatus.published;
};

const getSubtextColor = (subscription: SubscriptionPlan) => {
  if (subscription.published_at === null) {
    return "text-blue-600";
  } else {
    return "text-gray-600";
  }
};

const getSubtextForTitle = (subscription: SubscriptionPlan) => {
  if (subscription.archived_at !== null) {
    return "Archived: " + getFormattedDate(subscription.archived_at);
  } else if (subscription.published_at === null) {
    return "Draft";
  } else {
    return "Created: " + getFormattedDate(subscription.created_at);
  }
};

onMounted(() => {
  fetchSubscriptions();
});
</script>
