<template>
  <FixedActionBar
    button-type="submit"
    :is-loading="isSaving"
    :show-delete="true"
    :show-update="false"
    @delete="onDelete"
  >
    <AppButton class="mr-2" @click="createPublishedSubscription()">
      <span class="p-button-label">Publish</span>
    </AppButton>
    <AppButton class="mr-2" @click="submitForm()">
      <span class="p-button-label">Save Draft</span>
    </AppButton>
  </FixedActionBar>

  <AppCard class="mt-6 animate-fadein animate-duration-1000 text-surface-600">
    <div class="flex">
      <div class="flex flex-col w-full">
        <label for="title">Title</label>
        <InputText
          id="title"
          v-model="form.title"
          type="text"
          placeholder="Title"
          :class="{
            'p-invalid border-red-500': v$.title.$error,
          }"
          class="mt-1 block w-full rounded-md shadow-sm border-gray-300"
        />
        <small
          v-for="error in v$.title.$errors"
          :key="error.$uid"
          class="text-red-500 mt-1"
        >
          {{ error.$message }}
        </small>
      </div>
    </div>
    <div class="flex">
      <div class="flex flex-col w-full mt-4">
        <label for="description">Description</label>
        <InputText
          id="description"
          v-model="form.description"
          type="text"
          placeholder="Description"
          class="mt-1 block w-full rounded-md shadow-sm border-gray-300"
        />
      </div>
    </div>
    <div class="flex">
      <div class="flex flex-col gap-4 w-full mt-4">
        <Select
          v-model="form.payment_frequency"
          :options="frequencyOptions"
          :class="{
            'p-invalid border-red-500': v$.payment_frequency.$error,
          }"
          option-label="label"
          option-value="value"
          placeholder="Payment Frequency"
          class="w-full"
        />
        <small
          v-for="error in v$.payment_frequency.$errors"
          :key="error.$uid"
          class="text-red-500 mt-1"
        >
          {{ error.$message }}
        </small>
      </div>
    </div>
    <div class="flex">
      <div class="flex flex-col w-full mt-4">
        <label for="amount">Payment Amount</label>
        <InputNumber
          id="amount"
          v-model.number="form.amount"
          mode="currency"
          currency="USD"
          :step="0.01"
          :min="0.01"
          locale="en-US"
          placeholder="Amount"
          :class="{
            'p-invalid border-red-500': v$.amount.$error,
          }"
          class="mt-1 block w-full rounded-md shadow-sm border-gray-300"
        />
        <small
          v-for="error in v$.amount.$errors"
          :key="error.$uid"
          class="text-red-500 mt-1"
          data-testid="amount-error"
        >
          {{ error.$message }}
        </small>
      </div>
    </div>
  </AppCard>
  <AppCard class="mt-4">
    <div class="text-xl my-4">Documents</div>
    <div class="flex mb-4">
      <div class="flex w-full items-center">
        <Checkbox
          v-model="form.documents_require_acceptance"
          input-id="documents_require_acceptance"
          :binary="true"
          class="mr-2"
        />
        <span>Require subscribers to review and agree to documents.</span>
      </div>
    </div>

    <div class="flex flex-col w-full">
      <div class="flex flex-wrap gap-4 mb-4">
        <div
          v-for="asset in adminAssets"
          :key="asset?.id"
          class="flex flex-col"
        >
          <AssetThumbnail :asset-prop="asset" class="mt-2" box-size="140px" />
          <div class="flex">
            <Tag
              value="Delete"
              class="cursor-pointer mt-6"
              severity="danger"
              @click="() => deleteAttachmentConfirm(asset?.id)"
            >
              Delete
            </Tag>
          </div>
        </div>
      </div>

      <div class="w-full">
        <FileDropzone
          :organization-id="organizationId"
          :only-documents-allowed="true"
          :clear-previous="false"
          :max-file-size="MAX_FILE_SIZE"
          @completed="onDocumentsUploaded"
          @failed="uploadFailed"
        />
      </div>
    </div>
  </AppCard>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { helpers, minValue, required } from "@vuelidate/validators";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Select from "primevue/select";
import Tag from "primevue/tag";
import { PaymentFrequencyEnum } from "@/api/model/paymentFrequencyEnum";
import AppCard from "@/shared/components/AppCard.vue";
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import { useOrganizations } from "@/composables/useOrganizations";
import { getFrequencyDisplayValue } from "@/modules/subscriptions/helpers";
import FileDropzone from "@/shared/components/FileDropzone.vue";
import { createSubscriptionPlan } from "../api";
import { AssetType, EnrollmentStatusEnum, type AdminAsset } from "@/api/model";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { type SubscriptionPlanForm } from "@/types";
import AssetThumbnail from "@/shared/components/AssetThumbnail.vue";
import { useToastStore } from "@/shared/stores/toastStore";
import { addMixpanelEvent, EVENT_NAMES } from "@/shared/utils/analytics";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const toast = useToast();
const toastStore = useToastStore();
const confirm = useConfirm();
const isSaving = ref(false);
const { defaultOrganization } = useOrganizations();
const organizationId = ref<number>(0);
const adminAssets = ref<AdminAsset[]>([]);

watch(defaultOrganization, (value) => {
  organizationId.value = value?.id || 0;
});

const emptySubscriptionPlanForm: SubscriptionPlanForm = {
  id: "0",
  title: "",
  amount: 0,
  description: "",
  payment_frequency: PaymentFrequencyEnum.monthly,
  documents_require_acceptance: false,
  subscription_documents: [],
  enrollment_status: EnrollmentStatusEnum.closed,
  published: false,
};

const frequencyOptions = Object.values(PaymentFrequencyEnum).map((v) => ({
  label: getFrequencyDisplayValue(v),
  value: v,
}));

const form = ref<SubscriptionPlanForm>(emptySubscriptionPlanForm);

const rules = computed(() => ({
  title: { required: helpers.withMessage("Title is required", required) },
  amount: {
    minValue: helpers.withMessage(
      "Subscription cost must exceed $0.00",
      minValue(0.01)
    ),
    required: helpers.withMessage("Amount is required", required),
  },
  payment_frequency: {
    required: helpers.withMessage("Frequency is required", required),
  },
}));

const v$ = useVuelidate(rules, form);

const submitForm = async () => {
  await v$.value.$validate();
  if (v$.value.$invalid) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Subscription fields are not valid.",
      life: 3000,
    });
    return;
  }
  try {
    await createSubscriptionPlan(form.value);
    setToastMessage();
    setMixpanelEvent();
    redirectToList();
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to publish subscription.",
      life: 3000,
    });
  }
};

const uploadFailed = async () => {
  toast.add({
    severity: "error",
    summary: "Error",
    detail: "Failed to upload documents",
    life: 3000,
  });
};

const onDocumentsUploaded = async (assetIds: number[]) => {
  const newAssets = assetIds.map((id) => ({
    id: id,
    type: AssetType.document,
  }));
  adminAssets.value = [...adminAssets.value, ...newAssets];

  const assets = assetIds.map((id) => ({ asset_id: id }));
  form.value.subscription_documents = [
    ...form.value.subscription_documents,
    ...assets,
  ];

  toast.add({
    severity: "success",
    summary: "Success",
    detail: "Documents uploaded successfully",
    life: 3000,
  });
};

const deleteAttachmentConfirm = (file_id: number) => {
  confirm.require({
    message: "Are you sure you want to delete this attachment?",
    header: "Delete Attachment",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        removeAttachment(file_id);
      } catch (e) {
        console.error(e);
      }
    },
  });
};

const removeAttachment = (file_id: number) => {
  form.value.subscription_documents = form.value.subscription_documents.filter(
    (doc) => doc.asset_id !== file_id
  );
  adminAssets.value = adminAssets.value.filter((asset) => asset.id !== file_id);
};

const createPublishedSubscription = async () => {
  form.value.published = true;
  submitForm();
};

const deleteAndRedirect = async () => {
  console.log("Delete record and redirect", form.value);
};

const onDelete = () => {
  if (form.value.id !== "0") {
    onConfirmDelete();
  } else {
    redirectToList();
  }
};

const redirectToList = () => {
  router.push({
    name: ROUTE_NAME.PAYMENT_SUBSCRIPTIONS,
  });
};

const setToastMessage = () => {
  if (form.value.published) {
    toastStore.setPendingToast({
      severity: "success",
      summary: "Success",
      detail: "Subscription published successfully",
      life: 3000,
    });
  } else {
    toastStore.setPendingToast({
      severity: "success",
      summary: "Success",
      detail: "Subscription saved successfully",
      life: 3000,
    });
  }
};

const setMixpanelEvent = () => {
  if (form.value.published) {
    addMixpanelEvent(EVENT_NAMES.SUBSCRIPTIONS.SubscriptionPublished);
  } else {
    addMixpanelEvent(EVENT_NAMES.SUBSCRIPTIONS.SubscriptionDraftSaved);
  }
};

const onConfirmDelete = async () => {
  confirm.require({
    message: "Are you sure you want to delete this subscription?",
    header: "Delete Subscription",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteAndRedirect();
      } catch (e) {}
    },
  });
};

onMounted(() => {
  organizationId.value = defaultOrganization.value?.id || 0;
});
</script>
