<template>
  <BaseLayout
    title="View Subscription"
    :breadcrumb="[
      {
        label: 'Subscriptions',
        to: {
          name: ROUTE_NAME.PAYMENT_SUBSCRIPTIONS,
        },
      },
      {
        label: subscription?.title,
      },
    ]"
  >
    <div class="flex justify-end mb-4 gap-2">
      <ActionsMenu :menu-items="menuItems" :dangerous-action="archiveAction" />
      <Button
        v-if="subscription?.share_url"
        type="button"
        :disabled="!subscription?.published"
        @click="copyUrl(subscription.share_url)"
      >
        <span>Copy Link</span>
        <i class="pi pi-copy ml-2"></i>
      </Button>
    </div>
    <div>
      <SubscriptionPlan
        v-if="subscription"
        :subscription="subscription"
        :memberships="memberships"
        :last-thirty-days-membership-count="lastThirtyDaysMembershipCount"
      />
      <div v-else class="text-center p-4 text-gray-500">
        Subscription plan not found.
      </div>
    </div>
    <ConfirmDialog group="enrollment">
      <template #message="slotProps">
        <div class="flex flex-col gap-4">
          <p class="whitespace-pre-line">{{ slotProps.message.message }}</p>
        </div>
      </template>
    </ConfirmDialog>
  </BaseLayout>
</template>

<script setup lang="ts">
import { useSubscription } from "@/modules/subscriptions/composables/useSubscription";
import BaseLayout from "@/shared/components/BaseLayout.vue";
import { ROUTE_NAME } from "@/shared/constants/routes";
import { useRoute } from "vue-router";
import router from "@/router";
import { onMounted, computed } from "vue";
import Button from "primevue/button";
import SubscriptionPlan from "@/modules/subscriptions/components/SubscriptionPlan.vue";
import { useConfirm } from "primevue/useconfirm";
import ActionsMenu from "@/shared/components/ActionsMenu.vue";
import { useToast } from "primevue/usetoast";
import { EnrollmentStatusEnum } from "@/api/model";
import ConfirmDialog from "primevue/confirmdialog";

const {
  subscription,
  memberships,
  fetchSubscription,
  fetchMemberships,
  lastThirtyDaysMembershipCount,
  publishSubscription,
  archiveSubscription,
  updateEnrollmentStatus,
  updateError,
} = useSubscription();

const route = useRoute();
const onClickEdit = () => {
  router.push({
    name: ROUTE_NAME.PAYMENT_SUBSCRIPTION_EDIT,
    params: { id: subscription.value?.id },
  });
};

const subscriptionId = route.params.id.toString();

const confirm = useConfirm();
const toast = useToast();

const ENROLLMENT_MESSAGES = {
  close: {
    title: "Close Enrollment",
    message:
      "Are you sure you want to close enrollment for this subscription?\n\nThis subscription will no longer be available for new subscribers to enroll. Current subscribers will not be affected.",
    confirmButton: "Close Enrollment",
  },
  open: {
    title: "Open Enrollment",
    message:
      "Are you sure you want to open enrollment for this subscription?\n\nThis will allow new subscribers to enroll.",
    confirmButton: "Open Enrollment",
  },
};

const PUBLISH_MESSAGE = {
  title: "Publish Subscription",
  message:
    "Are you sure you would like to Publish this Subscription?\n\nThis will make the subscription available for signups at the sharable link.",
  confirmButton: "Publish",
};

const onClickPublish = () => {
  confirm.require({
    group: "enrollment",
    message: PUBLISH_MESSAGE.message,
    header: PUBLISH_MESSAGE.title,
    acceptLabel: PUBLISH_MESSAGE.confirmButton,
    rejectLabel: "Cancel",
    accept: async () => {
      const success = await publishSubscription(subscriptionId);
      if (success) {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Subscription published successfully",
          life: 3000,
        });
      } else if (updateError.value) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: updateError.value,
          life: 3000,
        });
      }
    },
  });
};

const onClickEnrollment = () => {
  const isOpen =
    subscription.value?.enrollment_status === EnrollmentStatusEnum.open;
  const dialogConfig = isOpen
    ? ENROLLMENT_MESSAGES.close
    : ENROLLMENT_MESSAGES.open;

  confirm.require({
    group: "enrollment",
    message: dialogConfig.message,
    header: dialogConfig.title,
    acceptLabel: dialogConfig.confirmButton,
    rejectLabel: "Cancel",
    accept: async () => {
      const success = await updateEnrollmentStatus(
        subscriptionId,
        isOpen ? EnrollmentStatusEnum.closed : EnrollmentStatusEnum.open
      );
      if (success) {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: `Enrollment ${isOpen ? "closed" : "opened"} successfully`,
          life: 3000,
        });
      } else if (updateError.value) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: updateError.value,
          life: 3000,
        });
      }
    },
  });
};

const archiveAction = computed(() => ({
  label: "Archive Subscription",
  icon: "pi pi-trash",
  dangerous: true as const,
  disabled: (subscription.value?.subscriber_membership_count ?? 0) > 0,
  command: () => {
    confirm.require({
      message: "Are you sure you want to archive this subscription plan?",
      header: "Confirm Archive",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        const success = await archiveSubscription(subscriptionId);
        if (success) {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Subscription archived successfully",
            life: 3000,
          });
          // Optionally redirect to subscription list after archiving
          router.push({ name: ROUTE_NAME.PAYMENT_SUBSCRIPTIONS });
        } else if (updateError.value) {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: updateError.value,
            life: 3000,
          });
        }
      },
    });
  },
}));

const menuItems = computed(() => [
  {
    label: "Edit",
    icon: "pi pi-pencil",
    command: onClickEdit,
  },
  {
    label: "Publish",
    icon: "pi pi-send",
    disabled: subscription.value?.published,
    command: onClickPublish,
  },
  {
    label:
      subscription.value?.enrollment_status === EnrollmentStatusEnum.open
        ? "Close Enrollment"
        : "Open Enrollment",
    icon:
      subscription.value?.enrollment_status === EnrollmentStatusEnum.open
        ? "pi pi-lock"
        : "pi pi-lock-open",
    command: onClickEnrollment,
  },
]);

const copyUrl = async (url: string) => {
  try {
    await navigator.clipboard.writeText(url);
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "URL copied to clipboard",
      life: 5000,
    });
  } catch (err) {
    console.error("Failed to copy URL: ", err);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to copy URL",
      life: 5000,
    });
  }
};

onMounted(async () => {
  await Promise.all([
    fetchSubscription(subscriptionId),
    fetchMemberships(subscriptionId),
  ]);
});
</script>
