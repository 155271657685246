import {
  adminV1PaymentsSubscriptionPlansCreate,
  adminV1PaymentsSubscriptionPlansList,
  adminV1PaymentsSubscriptionPlansRetrieve,
  adminV1PaymentsSubscriptionPlansPartialUpdate,
} from "@/api/admin-payments-subscription-plans/admin-payments-subscription-plans";
import {
  adminV1PaymentsSubscriptionPlansSubscriberMembershipsList,
  type AdminV1PaymentsSubscriptionPlansSubscriberMembershipsListResult,
} from "@/api/admin-payments-subscriber-memberships/admin-payments-subscriber-memberships";
import type {
  PaginatedSubscriptionPlanList,
  SubscriptionDocument,
  SubscriptionPlan,
  EnrollmentStatusEnum,
  SubscriptionPlanUpdate,
} from "@/api/model";
import type { AdminV1PaymentsSubscriptionPlansListParams } from "@/api/model/adminV1PaymentsSubscriptionPlansListParams";
import type { NonReadonly, SubscriptionPlanForm } from "@/types";

export const getSubscriptions = async (
  params: AdminV1PaymentsSubscriptionPlansListParams
): Promise<PaginatedSubscriptionPlanList | { error: string }> => {
  try {
    const response: PaginatedSubscriptionPlanList =
      await adminV1PaymentsSubscriptionPlansList(params);
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const getSubscription = async (
  id: string
): Promise<SubscriptionPlan | { error: string }> => {
  try {
    const response: SubscriptionPlan =
      await adminV1PaymentsSubscriptionPlansRetrieve(id);
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const createSubscriptionPlan = async (
  subscriptionPlan: SubscriptionPlanForm
): Promise<SubscriptionPlan> => {
  const docs: SubscriptionDocument[] =
    subscriptionPlan.subscription_documents.map((doc) => ({
      asset_id: doc.asset_id,
      asset: null as any,
      created_at: "",
      id: "",
      updated_at: "",
    }));

  const subscriptionPlanData: NonReadonly<SubscriptionPlan> = {
    amount: subscriptionPlan.amount,
    description: subscriptionPlan.description,
    documents_require_acceptance: subscriptionPlan.documents_require_acceptance,
    enrollment_status: subscriptionPlan.enrollment_status,
    payment_frequency: subscriptionPlan.payment_frequency,
    published: subscriptionPlan.published,
    subscription_documents: docs,
    title: subscriptionPlan.title,
  };
  const response: SubscriptionPlan =
    await adminV1PaymentsSubscriptionPlansCreate(subscriptionPlanData);
  return response;
};

export const getSubscriptionPlanMemberships = async (
  id: string
): Promise<
  | AdminV1PaymentsSubscriptionPlansSubscriberMembershipsListResult
  | { error: string }
> => {
  try {
    const response =
      await adminV1PaymentsSubscriptionPlansSubscriberMembershipsList(id, {
        limit: 10000,
        offset: 0,
      });
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};

type SubscriptionAction =
  | { type: "publish" }
  | { type: "archive" }
  | { type: "enrollment"; status: EnrollmentStatusEnum };

export const updateSubscriptionPlan = async (
  id: string,
  action: SubscriptionAction
): Promise<SubscriptionPlanUpdate | { error: string }> => {
  try {
    let updateData: Partial<NonReadonly<SubscriptionPlan>> = {};

    switch (action.type) {
      case "publish":
        updateData = { published: true };
        break;
      case "archive":
        updateData = { archived: true };
        break;
      case "enrollment":
        updateData = { enrollment_status: action.status };
        break;
    }

    const response: SubscriptionPlanUpdate =
      await adminV1PaymentsSubscriptionPlansPartialUpdate(id, updateData);
    return response;
  } catch (e: any) {
    return { error: e.message };
  }
};
