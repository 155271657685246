import { PaymentFrequencyEnum } from "@/api/model";

export const getFormattedDate = (date: string) => {
  const parsedDate = new Date(date);

  if (isNaN(parsedDate.getTime())) {
    console.error("Invalid date");
    return "";
  }

  const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = parsedDate.getDate().toString().padStart(2, "0");
  const year = parsedDate.getFullYear().toString().slice(-2);

  return `${month}/${day}/${year}`;
};

const frequencyDisplayMap: Record<PaymentFrequencyEnum, string> = {
  [PaymentFrequencyEnum.weekly]: "Weekly",
  [PaymentFrequencyEnum.every_two_weeks]: "Every 2 Weeks",
  [PaymentFrequencyEnum.monthly]: "Monthly",
  [PaymentFrequencyEnum.quarterly]: "Quarterly",
  [PaymentFrequencyEnum.annually]: "Annually",
  [PaymentFrequencyEnum.every_six_months]: "Every 6 Months",
  [PaymentFrequencyEnum["one-time"]]: "One Time",
};

export const getFrequencyDisplayValue = (
  paymentFrequency?: PaymentFrequencyEnum
) => {
  if (!paymentFrequency) return "";
  return frequencyDisplayMap[paymentFrequency];
};
